/**
 * Do not show cookie banner for a certain page, e.g. the login page.
 */
const preDecisionGatewayHasPageClass = (classNames, classNameShowAlways) => async () => {
  const {
    className
  } = document.body;
  if (className && className.indexOf(classNameShowAlways) > -1) {
    return false;
  }
  return classNames.filter(c => className.indexOf(c) > -1).length > 0 ? "consent" : false;
};
export { preDecisionGatewayHasPageClass };