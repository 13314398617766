import { mapValueSuffixPx } from ".";
import fastdom from "fastdom";
const createRenderedDimensionVariables = (varName, vars) => {
  let varWidth;
  let varHeight;
  let variableConsumer;
  if (vars) {
    variableConsumer = vars({
      width: 0,
      height: 0,
      scrollbar: false,
      scrolledTop: false,
      scrolledBottom: false
    }, {
      width: mapValueSuffixPx,
      height: mapValueSuffixPx
    });
  } else {
    varWidth = varName("width");
    varHeight = varName("height");
  }
  const disposeFns = [];
  const disconnect = () => disposeFns.forEach((fn, index, arr) => {
    fn();
    arr.splice(index, 1);
  });
  const observe = function (element) {
    let dependencies = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    let lastCreatedTask;
    const recalculate = () => {
      if (!element) {
        return;
      }
      fastdom.clear(lastCreatedTask);
      lastCreatedTask = fastdom.measure(() => {
        const {
          width,
          height
        } = element.getBoundingClientRect();
        const {
          clientHeight,
          scrollHeight,
          scrollTop,
          offsetHeight
        } = element;
        const scrollbar = clientHeight < scrollHeight;
        const scrolledBottom = Math.ceil(scrollTop + offsetHeight) >= scrollHeight;

        // The element got invisible, do not change anything
        if (width === 0 && height === 0 && !element.offsetParent) {
          return;
        }
        fastdom.mutate(() => {
          if (variableConsumer) {
            variableConsumer[1]({
              width,
              height,
              scrollbar,
              scrolledTop: scrollTop === 0,
              scrolledBottom
            });
          } else {
            element.style.setProperty(varWidth, `${width}px`);
            element.style.setProperty(varHeight, `${height}px`);
          }
        });
      });
    };
    for (const e of [element, ...dependencies]) {
      if (!e) {
        continue;
      }

      // Support animations
      e.addEventListener("animationend", recalculate);
      e.addEventListener("scroll", recalculate);
      const ro = new ResizeObserver(recalculate);
      ro.observe(e);
      disposeFns.push(() => {
        ro.disconnect();
        e.removeEventListener("animationend", recalculate);
        e.removeEventListener("scroll", recalculate);
      });
    }
    return disconnect;
  };
  return vars ? [observe, variableConsumer[0], disconnect] : [observe, {
    width: varWidth,
    height: varHeight
  }, disconnect];
};
export { createRenderedDimensionVariables };